// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=K2D:wght@500&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#root {
  display: flex;
  flex: 1 1;
}
*{
  font-family: 'K2D', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,SAAO;AACT;AACA;EACE,8BAA8B;AAChC","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=K2D:wght@500&display=swap');\n\n#root {\n  display: flex;\n  flex: 1;\n}\n*{\n  font-family: 'K2D', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
