// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

a {
  color: white;
  text-decoration: none;
}

.contactLinkA{
  color: #3994FF
}
.contactLinkA:hover{
  color: rgb(15, 80, 221)
}

.igLogoA:hover{
  color: white
}

a:hover {
  color: #3994FF;
  text-decoration:none; 
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

h3 {
  font-size: 2em;
  line-height: 1.5;
}

strong {
  font-weight: bold;
}

button {
  border-radius: 8px;
  border: 2px solid transparent;
  padding: 0.9em 1.4em;
  font-size: 1.1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #3994FF;
  cursor: pointer;
  transition: border-color 0.25s;
  color: white;
  border-color: white;
}
button:hover {
  border-color: #3994FF;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #3994FF;
    color: white
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE;AACF;AACA;EACE;AACF;;AAEA;EACE;AACF;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,6BAA6B;EAC7B,oBAAoB;EACpB,gBAAgB;EAChB,gBAAgB;EAChB,oBAAoB;EACpB,yBAAyB;EACzB,eAAe;EACf,8BAA8B;EAC9B,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,qBAAqB;AACvB;AACA;;EAEE,0CAA0C;AAC5C;;AAEA;EACE;IACE,cAAc;IACd,yBAAyB;EAC3B;EACA;IACE,cAAc;EAChB;EACA;IACE,yBAAyB;IACzB;EACF;AACF","sourcesContent":["\n\na {\n  color: white;\n  text-decoration: none;\n}\n\n.contactLinkA{\n  color: #3994FF\n}\n.contactLinkA:hover{\n  color: rgb(15, 80, 221)\n}\n\n.igLogoA:hover{\n  color: white\n}\n\na:hover {\n  color: #3994FF;\n  text-decoration:none; \n}\n\nbody {\n  margin: 0;\n  display: flex;\n  place-items: center;\n  min-width: 320px;\n  min-height: 100vh;\n}\n\nh1 {\n  font-size: 3.2em;\n  line-height: 1.1;\n}\n\nh3 {\n  font-size: 2em;\n  line-height: 1.5;\n}\n\nstrong {\n  font-weight: bold;\n}\n\nbutton {\n  border-radius: 8px;\n  border: 2px solid transparent;\n  padding: 0.9em 1.4em;\n  font-size: 1.1em;\n  font-weight: 500;\n  font-family: inherit;\n  background-color: #3994FF;\n  cursor: pointer;\n  transition: border-color 0.25s;\n  color: white;\n  border-color: white;\n}\nbutton:hover {\n  border-color: #3994FF;\n}\nbutton:focus,\nbutton:focus-visible {\n  outline: 4px auto -webkit-focus-ring-color;\n}\n\n@media (prefers-color-scheme: light) {\n  :root {\n    color: #213547;\n    background-color: #ffffff;\n  }\n  a:hover {\n    color: #747bff;\n  }\n  button {\n    background-color: #3994FF;\n    color: white\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
